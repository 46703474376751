import { MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    icon: 'Swap',
    href: '/swap',
    hideSubNav:false,
    type: DropdownMenuItemType.EXTERNAL_LINK,
    // showItemsOnMobile: false,
    items: [
      {
        label: t('Swap'),
        // href: '/swap',
        href: 'https://pretzelswapreact.maticz.in/swap',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Liquidity'),
        // href: '/liquidity',
        href: 'https://pretzelswapreact.maticz.in/liquidity',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
  {
    label: t('Earn'),
    href: '/farms',
    hideSubNav:true,
    // href: 'https://donutswapreact.maticz.in/farms',
    type: DropdownMenuItemType.EXTERNAL_LINK,

    icon: 'Earn',
    items: [
      {
        label: t('Farms'),
        // href: '/farms',
        href: 'https://pretzelswapreact.maticz.in/farms',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Pools'),
        // href: '/farms',
        href: 'https://pretzelswapreact.maticz.in/pools',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Positions'),
        // href: '/pools',
        href: 'https://pretzelswapreact.maticz.in/positions',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('PZLX Pools'),
        // href: '/pools',
        href: 'https://pretzelswapreact.maticz.in/pzlxpools',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },

    ],
  },
  {
    label: t('PZLX'),
    href: '/pzlx',
    hideSubNav:true,
    type: DropdownMenuItemType.EXTERNAL_LINK,
    // onClick:() => {window.location.href="https://donutswapreact.maticz.in/yum"},
    icon: 'Proposal',
    items: [
      {
        label: t('PZLX'),
        href: 'https://pretzelswapreact.maticz.in/pzlx',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Dividends'),
        href: 'https://pretzelswapreact.maticz.in/dividends',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Launchpad'),
        href: 'https://pretzelswapreact.maticz.in/launchpadpzlx',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Yield Booster'),
        href: 'https://pretzelswapreact.maticz.in/yieldbooster',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
  {
    label: t('Launchpad'),
    href: '/launchpad',
    hideSubNav:false,
    type: DropdownMenuItemType.EXTERNAL_LINK,
    icon: 'Rocket',
    showItemsOnMobile: false,
    items: [
    //   {label: t('Launchpad'),
    //         href: 'https://donutswapreact.maticz.in/launchpad',
    //         type: DropdownMenuItemType.EXTERNAL_LINK
    // }
    ],
  },
  {
    label: t('Analytics'),
    href: '/info',
    hideSubNav:true,
    icon: 'Info',
    items: [
      {
        label: t('Overview'),
        href: '/info',
      },
      {
        label: t('Pools'),
        href: '/info/pools',
      },
      {
        label: t('Tokens'),
        href: '/info/tokens',
      },
    ],
  },
  // {
  //   label: t('More'),
  //   href: '/pzlx',
  //   hideSubNav:true,
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  //   // onClick:() => {window.location.href="https://pretzelswapreact.maticz.in/pzlx"},
  //   icon: 'More',
  //   items: [
  //     {
  //       label: t('PZLX'),
  //       href: 'https://pretzelswapreact.maticz.in/pzlx',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Launchpad'),
  //       href: 'https://pretzelswapreact.maticz.in/launchpad',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Yield Booster'),
  //       href: 'https://pretzelswapreact.maticz.in/yieldbooster',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     // {
  //     //   label: t('Bakery'),
  //     //   href: 'https://pretzelswapreact.maticz.in/bakery',
  //     //   type: DropdownMenuItemType.EXTERNAL_LINK,
  //     // },
  //   ],
  // }
 
  // {
  //   label: t('More'),
  //   href: '/',
  //   icon: 'Nft',
  //   hideSubNav:true,
  //   items: [
  //     {
  //       label: t('Margin Trading'),
  //       href: 'http://pzl.maticz.in/',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,

  //     }
    
     
     
  //   ],
  // },
  // {
  //   label: t('NFT'),
  //    href: '/nft',
  //   icon: 'Voting'
  
  // },
  // {
  //   label: t('NFT'),
  //   href: '/nft',
  //   icon: 'Nft',
  //   showItemsOnMobile: false,
  //   items: [

  //   ],
  // },
  // {
  //   label: t('Referral'),
  //   href: '/referral',
  //   icon: 'Referral',
  //   items: [
  //
  //   ],
  // },
  // {
  //   label: t('Ido'),
  //   href: '/ido',
  //   icon: 'Ido',
  //   items: [

  //   ],
  // },

]

export default config
