import React from 'react'
import styled from 'styled-components'
import { Flex, Heading, Text} from '@pancakeswap/uikit'
import { Link } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Container from 'components/Layout/Container'
import { useWeb3React } from '@web3-react/core'
import SunburstSvg from './SunburstSvg'
import CompositeImage from './CompositeImage'


const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const StyledSunburst = styled(SunburstSvg)`
  height: 350%;
  width: 350%;

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 400%;
    width: 400%;
  }
`

const Wrapper = styled(Flex)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const FloatingPancakesWrapper = styled(Container)`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    visibility: visible;
  }
`

const TopLeftImgWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
`

const BottomRightImgWrapper = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 0;
`

const topLeftImage = {
  path: '/images/home/flying-pancakes/',
  attributes: [
    { src: '1-bottom', alt: 'Pancake flying on the bottom' },
    { src: '1-left', alt: 'Pancake flying on the left' },
    { src: '1-top', alt: 'Pancake flying on the top' },
  ],
}

const bottomRightImage = {
  path: '/images/home/flying-pancakes/',
  attributes: [
    { src: '2-bottom', alt: 'Pancake flying on the bottom' },
    { src: '2-top', alt: 'Pancake flying on the top' },
    { src: '2-right', alt: 'Pancake flying on the right' },
  ],
}

const Footer = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  return (
    <>
    <div className='common_footer'>
     <section className='footerTop'>
     {/* <div className='container'> */}

       <div className='container footer_container_inner'>
         <div className="flexFooter  align-items-start">
         <div className='gridFooter WidthSmall'>   
             <h3>Tools</h3> 
              <ul className='flex_grid_foter'>
                <li><a href='/info'>Analytics</a></li>
                <li><a href='/info'>Bridge</a></li>
                <li><a href='/info'>CoinGecko</a></li>
                <li><a href='/info'>DexTools</a></li>
                {/* <li><a href='/'>GeckoTerminal</a></li> */}
                <li><a href='/info'>Governance</a></li>
              </ul>
           </div>

           <div className='gridFooter WidthSmall'>   
             <h3>Ecosystem</h3>  
              <ul className='flex_grid_foter'>
                <li><a href='https://pretzelswapreact.maticz.in/pzlxpools'>Create a pool</a></li>
                <li><a href='https://pretzelswapreact.maticz.in/pools'>My pools</a></li>
              </ul>
           </div>

           <div className='gridFooter WidthSmall'>
             <h3>Resources</h3> 
              <ul className='flex_grid_foter'>
                <li><a href='/info'>Audit</a></li>
                <li><a href='/info'>Contact us</a></li>
                <li><a href='/info'>Contracts</a></li>
                <li><a href='/info'>Documentation</a></li>
              </ul>
           </div>
           <div className='gridFooter WidthSmall'>
             <h3>Help</h3> 
              <ul className='flex_grid_foter'>
                <li><a href='/info'>Faq</a></li>
                <li><a href='/info'>Guides</a></li>
                <li><a href='/info'>Support</a></li>
              </ul>
           </div>

          
         
        
           {/* <div className='gridFooter WidthSmall'>
             <h3>Support</h3>
              <ul className='flex_grid_foter'>
                <li><a href='/about-us'>About Us</a></li>
                <li><a href='/' target='_blank'>Guide</a></li>
              </ul>
           </div>
           <div className='gridFooter WidthSmall'>
             <h3>Support</h3>
              <ul className='flex_grid_foter'>
                <li><a href='/about-us'>About Us</a></li>
                <li><a href='/' target='_blank'>Guide</a></li>
              </ul>
           </div> */}
       
         </div>
       </div>
     </section>
     <section className='footerBottom'>
       <div className='container'>
        <div className='flex_footer_btm'>
        <div className='socialmedias'>
             <a href='https://twiiter.com/' rel="noreferrer" target="_blank">
              <img src="/images/media1.png" alt="" className='img-fluid mediass' />
              <img src="/images/mediahover1.png" alt="" className='img-fluid mediasshover' />
              </a>
              <a href='https://telegram.org/' rel="noreferrer" target="_blank">
              <img src="/images/media2.png" alt="" className='img-fluid mediass' />
              <img src="/images/mediahover2.png" alt="" className='img-fluid mediasshover' />
              </a>
              <a href='/info' rel="noreferrer" target="_blank">
              <img src="/images/media3.png" alt="" className='img-fluid mediass' />
              <img src="/images/mediahover3.png" alt="" className='img-fluid mediasshover' />
              </a>
              <a href='https://github.com/' rel="noreferrer" target="_blank">
              <img src="/images/media4.png" alt="" className='img-fluid mediass' />
              <img src="/images/mediahover4.png" alt="" className='img-fluid mediasshover' />
              </a>
             </div>

        <p className="text-center">Copyright &copy; 2024 PretzelSwap - All Rights Reserved.</p>
        <div className='gridFooter_tvl'>
             <span className='borders px-3 py-2'>
             <span><img src="/images/logoss.png" alt="" className='img-fluid mr-2' /></span>
              <span>$12,334</span>
             </span>

             <span className='borders px-3 py-3'>
             <span className='mr-2 text_orange_footer'>TVL</span>
              <span>$12,334</span>
             </span>
           </div>
        </div>
       </div>
     </section>
     </div>
    </>
  )
}

export default Footer
