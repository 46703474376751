import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wbnb: new Token(
    MAINNET,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  // rise: new Token(
  //   MAINNET,
  //   '0xa4e8f898e6a6abe8e3c71bf62eb5fef025353a01',
  //   18,
  //   'CBT',
  //   'Sparco Bank',
  //   'https://cbt.maticz.in/',
  // ),
  // sparco: new Token(
  //   MAINNET,
  //   '0xa4e8f898e6a6abe8e3c71bf62eb5fef025353a01',
  //   18,
  //   'CBT',
  //   'Sparco Bank',
  //   'https://cbt.maticz.in/',
  // ),
  // sum: new Token(
  //   MAINNET,
  //   '0x279199010Fa389942654719aC62cDA7496091dE3',
  //   18,
  //   'BSUM',
  //   'BSUM Bep20',
  //   'https://cbt.maticz.in/',
  // ),
  // trba: new Token(
  //   MAINNET,
  //   '0x6f702c1eeFd33908E9d80747C373158CE4eb8b52',
  //   18,
  //   'TRBA',
  //   'Tree Bank',
  //   'https://cbt.maticz.in/',
  // ),
  ada: new Token(
    MAINNET,
    '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    18,
    'ADA',
    'Cardano',
    'https://pretzelswap.maticz.in/',
  ),
  btcb: new Token(
    MAINNET,
    '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    18,
    'BTCB',
    'BTCB Token',
    'https://pretzelswap.maticz.in/',
  ),
  doge: new Token(
    MAINNET,
    '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    8,
    'DOGE',
    'DOGE Token',
    'https://pretzelswap.maticz.in/',
  ),
  dot: new Token(
    MAINNET,
    '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    18,
    'DOT',
    'Polkadot',
    'https://pretzelswap.maticz.in/',
  ),
  uni: new Token(
    MAINNET,
    '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
    18,
    'UNI',
    'UNI TOKEN',
    'https://pretzelswap.maticz.in/',
  ),
  meta: new Token(
    MAINNET,
    '0xfe750cbc661076F917bDd73Bf38708B5768dfd24',
    18,
    'META',
    'Meta Index',
    'https://pretzelswap.maticz.in/',
  ),
  cake: new Token(
    MAINNET,
    '0x38f63dc0e5ea3f70a6c66dc642691be98f0e2fdc',
    18,
    'SPARCO',
    'SPARCO Token',
    'https://pretzelswap.maticz.in/',
  ),
  co2: new Token(
    MAINNET,
    '0x93ae1Ea1A9Dab503C1aaae6897D90C4F3e9adFbd',
    18,
    'CO2',
    'CO2 Token',
    'https://pretzelswap.maticz.in/',
  ),
  busd: new Token(
    MAINNET,
    '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  tether: new Token(
    MAINNET,
    '0x55d398326f99059ff775485246999027b3197955',
    18,
    'Tether-USDT',
    'USDT',
    'https://www.paxos.com/busd/',
  ),
  syrup: new Token(
    MAINNET,
    '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pretzelswap.maticz.in/',
  ),
  bake: new Token(
    MAINNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
  ripple: new Token(
    MAINNET,
    '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
     18,
    'XRP',
    'Ripple Token',
    'https://www.bakeryswap.org/',
  ),
  pzl: new Token( 
    MAINNET,
    '0xa142b84718368eFd9F656A5782CD47FC33B0b985',
    18,
    'PZL',
    'CHEESEBURGER Token',
    'https://www.bakeryswap.org/',
  )
}

export const testnetTokens = {
  wbnb: new Token(
    TESTNET,
    '0x15690Bc1eA3083c747A31927a641c0566bf86A10',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  rise: new Token(
    TESTNET,
    '0x0ae315ba1485a4106dc2d924066ba016f8b04840',
    18,
    'SPARCO',
    'SPARCO Token',
    'http://wsum.wealwin.com/logo.png',
  ),
  sum: new Token(
    MAINNET,
    '0x752B357e2c8704597eA9D0CAAd2571A449743860',
    18,
    'SUM',
    'SUMCOIN',
    'http://wsum.wealwin.com/sumcoin.png',
  ),
  cake: new Token(
    TESTNET,
    '0x0ae315ba1485a4106dc2d924066ba016f8b04840',
    18,
    'SPARCO',
    'SPARCO Token',
    'https://pretzelswap.maticz.in/',
  ),
  co2: new Token(
    TESTNET,
    '0x96322336e6327a4d258026868175037fafdd1a1a',
    18,
    'CO2',
    'CO2',
    'https://pretzelswap.maticz.in/',
  ),
  busd: new Token(
    TESTNET,
    '0xf9b391Eef761F255Bcde2815fE615F5A1e8b0414',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  syrup: new Token(
    TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pretzelswap.maticz.in/',
  ),
  bake: new Token(
    TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
  pzl: new Token(
    TESTNET,
    '0xE04619f4e1108762F6bE0Ed26c94A9590aD71552',
    18,
    'PZL',
    'CHEESEBURGER Token',
    'https://www.bakeryswap.org/',
  )
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't

  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
